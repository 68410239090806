@import url(https://use.typekit.net/dwf5roh.css);

body {
  margin: 0;
  font-family: "din-2014", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.animated-text span {
  opacity: 0.3;
  animation: fadeIn 0.5s forwards 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
