html {
	scroll-behavior: auto;
	 /* overflow: auto; */
}
/* body {
	background-color: #01153C;
} */
a {
	color: unset;
	text-decoration: none;
}

h1 {
	font-size: 8rem;
}

h2 {
	font-size: 5rem;
}

h3 {
	font-size: 2.6rem;
}

h4 {
	font-size: 2rem;
}

h5 {
	font-size: 1.6rem;
}

h6 {
	font-size: 1.4rem;
}

p {
	font-size: 1.2rem;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.html-section img{
	max-width: 100%;
	height: auto;
	margin: auto;
	text-align: center;
}
.html-section p, .html-section span{
	font-family: 'din-2014' !important;
}
.html-section a{
	position: relative;
	font-weight: bold;
	text-decoration: none !important;
	text-wrap: nowrap !important;
}
.html-section a::after{
	content:'';
	position:absolute;
	bottom:-2px;
	left:0;
	width:0;
	height:2px;
	background:#FFCD00;
	transition: width 0.25s;
}
.html-section a:hover::after{
	width:100%;
}
@media(max-width:600px){
	.html-section a{
		text-wrap: wrap !important;
	}
}
.html-section ul{
	font-size: 1.2rem !important;
}
.html-section figure{
	margin: 0 auto !important;
}